import {Event, EventArguments} from './event';
import {generateGovolteId} from './idGenerator';
import {PageContext, PAGE_HOME, getNmoId, setNmoId} from './pageTracker';
import {TagPlugin} from './plugin';
import {debug, LogLevel, setLogLevel, warn} from './utils/log';
import {
  PARTY_ID_KEY,
  PARTY_ID_TTL_DAYS,
  SESSION_ID_KEY,
  SESSION_ID_TTL_DAYS,
} from './storage/keys';
import {EventType} from './eventType';
import {StreamContext} from './streamTracker';
import {RecommendationContext} from './recommendationTracker';
import {DOMUtils} from './utils/dom';
import {createStorage, getStorage} from './storage';

const TAG = 'NPOTag';

/**
 * Provides tracking context to the {@link NPOTag}.
 *
 * @remarks
 * This context object contains properties shared across all NPO trackers.
 */
export type NPOContext = InitialisationProps & UserProps;

/**
 * Denotes the environment in which the tag is applied.
 * */
const environmentTypes = ['dev', 'preprod', 'prod'] as const;
export type EnvironmentType = typeof environmentTypes[number];

/**
 * Global properties set at {@link NPOTag} initialisation.
 */

interface InternalInitializationProps {
  readonly debug?: boolean;
  readonly brand: string;
  readonly brand_id: number;
  readonly platform: string;
  readonly platform_version: string;
  readonly environment?: EnvironmentType;
  readonly disableNmoDam?: boolean;
  readonly serializedSessionInfo?: string;
}

/**
 * Initialization generally used to create a {@link NPOTag} instance when not resuming a session from another device.
 */
export type InitialisationProps = Omit<
  InternalInitializationProps,
  'serializedSessionInfo'
>;

/**
 * Initialization properties when resuming a session from another device.
 */
export type FromSessionInitialisationProps = Omit<
  InternalInitializationProps,
  'brand' | 'brand_id' | 'platform' | 'platform_version'
> & {serializedSessionInfo: string};

/**
 * User-related context properties.
 */
export interface UserProps {
  readonly user_profile?: string;
  readonly user_subscription?: string;
  readonly user_pseudoid?: string;
  readonly user_id?: string;
  readonly user_profile_type?: string;
}

/**
 * Default values for {@link NPOContext} props
 */
export const NPOContextDefaults: Partial<NPOContext> = {
  user_subscription: 'anonymous',
};

/**
 * Internal representation of session data.
 *
 * Users of the SDK should only have to deal with the serialized (string) format produced by
 * {@link NPOTag#getSerializedSessionInfo}.
 */
export interface SessionInfo {
  brand: string;
  brandId: number;
  platform: string;
  platformVersion: string;
  govoltePartyId: string;
  sessionId: string;
  npoUserId?: string;
  npoProfileId?: string;
  npoPseudoId?: string;
  npoSubscription?: string;
  nmoId: string;
  // The final object also includes any values returned by the plugins.
}

/**
 * NPOTag interface used to send events and page views to analytics plugins.
 *
 * @example
 * Usage:
 * ```
 * const tag = newTag({
 *   brand: 'npoportal',
 *   brand_id: 4,
 *   etc...
 * });
 * tag.login({
 *   user_profile: 'fe124-234-he123-gy23',
 *   user_subscription: 'premium',
 *   user_pseudoid: 'ge3523-2342-de223',
 * })
 * console.log(tag.getContext());
 * ```
 */
export interface NPOTag {
  /**
   * Get the current shared context properties
   */
  getContext: () => NPOContext;
  /**
   * Set the logged-in user's props on the context object
   */
  login: (userProps: UserProps) => void;
  /**
   * Clear the logged in user's props
   */
  logout: () => void;
  /**
   * Get list of configured {@link TagPlugin} objects
   */
  getPlugins: () => TagPlugin[];
  /**
   * Get current partyId string
   */
  getParty: () => string;
  /**
   * Get boolean indicating that partyId is newly created in this session
   */
  getIsNewParty: () => boolean;
  /**
   * Get current sessionId string or generate a new one if session is expired
   */
  getSession: () => string;
  /**
   * Get a serialized session data object, which can be sent to another device
   * to 'take over' the session. For example, when a user resumes their
   * session on a Chromecast device.
   */
  getSerializedSessionInfo: () => string;
}

/**
 * Initialises the shared context for {@link NPOTag}.
 * Can only be initialised when window is available. Window is required because
 * the library depends on `crypto` object that only exists when window is available.
 *
 * @remarks
 * Default values: see {@link NPOContextDefaults}
 *
 * @param context {@link NPOContext} object containing at least the required properties
 * @param plugins {@link TagPlugin} list of plugins
 * @param logLevel {@link LogLevel}
 * @returns If window is available, it returns an initialised {@link NPOTag} object.
 *
 * @example Minimal usage:
 * ```
 * const tag = newTag({
 *   brand: 'npoportal',
 *   brand_id: 4,
 *   platform: 'site',
 *   platform_version: 'npo-site-0.1',
 * })
 * ```
 * @example Complete usage:
 * ```
 * const tag = newTag({
 *   debug: true,
 *   brand: 'npoportal',
 *   brand_id: 4,
 *   platform: 'site',
 *   platform_version: 'npo-site-0.1',
 *   environment: 'preprod',
 *   disableNmoDam: true,
 * })
 * tag.login({
 *   user_profile: 'fe124-234-he123-gy23',
 *   user_subscription: 'premium',
 *   user_pseudoid: 'ge3523-2342-de223',
 *   user_id: 'id-of-user',
 *   user_profile_type: 'kids_6',
 * })
 * ```
 */
export function newTag(
  context: InitialisationProps,
  plugins?: TagPlugin[],
  logLevel?: LogLevel
): NPOTag | undefined {
  return _newTag(context, plugins, logLevel);
}

/**
 * Internal function to support both tag creation from scratch or from session.
 *
 * @param context {@link InternalInitializationProps} properties that may include session information
 * @param plugins {@link TagPlugin} list of plugins
 * @param logLevel {@link LogLevel}
 * @returns
 */

const _newTag = function (
  context: InternalInitializationProps,
  plugins?: TagPlugin[],
  logLevel?: LogLevel
): NPOTag | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const storage = createStorage();

  const existingSessionInfo: SessionInfo | undefined =
    context.serializedSessionInfo
      ? JSON.parse(context.serializedSessionInfo)
      : undefined;

  const validateEnvironment = (context: InitialisationProps): boolean => {
    if (
      context.environment === undefined ||
      environmentTypes.includes(context.environment)
    ) {
      return true;
    }
    const validValueList = environmentTypes.map(env => `'${env}'`).join(', ');
    warn(
      TAG,
      `Invalid environment type. Must be one of ${validValueList}. Received: '${context.environment}'. Environment will be ignored.`
    );
    return false;
  };

  // Apply defaults to configured context
  let sharedContext = {
    ...NPOContextDefaults,
    ...context,
    environment: validateEnvironment(context) ? context.environment : undefined,
  };

  if (context.debug) {
    setLogLevel(LogLevel.DEBUG);
  } else {
    setLogLevel(logLevel ?? LogLevel.ERROR);
  }

  if (context.disableNmoDam) {
    warn(
      TAG,
      'Disabling the NMO-DAM measurement in the NPOTag risks running old/incompatible implementations that may harm representation in the NMO-DAM statistics. Doing so is at your own risk.'
    );
  }

  // Fetch stored partyId if available, or generate a new one
  const restoredPartyId =
    existingSessionInfo?.govoltePartyId || storage.get(PARTY_ID_KEY);
  const partyId = restoredPartyId || generateGovolteId();
  // Store boolean to indicate if partyId was generated in this session or not
  const didGeneratePartyId = !restoredPartyId;

  // Log partyId in debug mode
  debug(
    TAG,
    `${didGeneratePartyId ? 'Generated new' : 'Using'} partyId: ${partyId}`
  );

  /**
   * Fetch stored session id if available, or generate a new one
   * @returns sessionId string
   */
  const getSession = () => {
    const restoredSessionId =
      existingSessionInfo?.sessionId || storage.get(SESSION_ID_KEY);
    const sessionId = restoredSessionId || generateGovolteId();
    // Store session id as name to indicate sessionId is newly created
    if (!restoredSessionId) {
      storage.set(sessionId, '', {
        expires: SESSION_ID_TTL_DAYS,
      });
    }
    // Log sessionId in debug mode
    debug(
      TAG,
      `${restoredSessionId ? 'Using' : 'Generated new'} sessionId: ${sessionId}`
    );
    return sessionId;
  };

  // Refresh sessionId if necessary on tag creation
  const sessionId = getSession();
  storage.set(SESSION_ID_KEY, sessionId, {
    expires: SESSION_ID_TTL_DAYS,
  });

  /**
   * If the partyId was generated in this session, we store the sessionId using the
   * partyId as the key.
   * This value is used to maintain the value of isNewParty when a session is resumed
   * (see {@link getIsNewParty}).
   */
  if (didGeneratePartyId) {
    storage.set(partyId, sessionId, {
      expires: SESSION_ID_TTL_DAYS,
    });
  }

  // Do we have an existing session to take over? (ie. we're running on a chromecast)
  if (existingSessionInfo) {
    if (existingSessionInfo.npoUserId) {
      // auto login
      sharedContext.user_id = existingSessionInfo.npoUserId;
      sharedContext.user_profile = existingSessionInfo.npoProfileId;
      sharedContext.user_pseudoid = existingSessionInfo.npoPseudoId;
      sharedContext.user_subscription = existingSessionInfo.npoSubscription;
    }
    setNmoId(existingSessionInfo.nmoId);

    // Initialize plugins
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pluginData = existingSessionInfo as any;
    plugins?.forEach(plugin => plugin.initializeFromSessionInfo(pluginData));
  }

  /**
   * Get boolean to represent if partyId is newly created in this session
   * @returns true if partyId is newly generated in this session, false otherwise
   * @remarks
   * If a new partyId was generated in the current session, we store the current
   * sessionId using the partyId as the key. This is used to check if the current
   * partyId was generated during the session represented by our current sessionId.
   * By using storage, we make the value of isNewParty resilient to sessions being
   * resumed from storage.
   * If the partyId was not generated in this session, we remove the value from storage.
   */
  const getIsNewParty = () => {
    const sessionId = storage.get(SESSION_ID_KEY);
    const isNewParty =
      sessionId !== undefined && storage.get(partyId) === sessionId;
    if (isNewParty) {
      storage.set(partyId, sessionId, {
        expires: SESSION_ID_TTL_DAYS,
      });
    } else {
      storage.remove(partyId);
    }
    return isNewParty;
  };

  const getSerializedSessionInfo = () => {
    const coreData: SessionInfo = {
      brand: sharedContext.brand,
      brandId: sharedContext.brand_id,
      platform: sharedContext.platform,
      platformVersion: sharedContext.platform_version,
      govoltePartyId: partyId,
      sessionId: sessionId,
      nmoId: getNmoId(),
      // only when logged in
      npoUserId: sharedContext.user_id,
      npoProfileId: sharedContext.user_profile,
      npoPseudoId: sharedContext.user_pseudoid,
      npoSubscription: sharedContext.user_subscription,
    };
    // add information provided by plugins
    let data: {[key: string]: string | number | undefined} = {...coreData};
    if (plugins) {
      plugins.forEach(plugin => {
        data = {
          ...data,
          ...plugin.getSessionInfo(),
        };
      });
    }
    // remove undefined values
    Object.keys(data)
      .filter(k => data[k] === undefined)
      .forEach(k => delete data[k]);
    return JSON.stringify(data);
  };

  return {
    getContext: () => ({...sharedContext}),
    login: userProps => {
      debug(TAG, `Login with userId ${userProps.user_id}`);
      sharedContext = {...sharedContext, ...userProps};
    },
    logout: () => {
      debug(TAG, 'Logout');
      sharedContext = {
        ...sharedContext,
        user_profile: NPOContextDefaults.user_profile,
        user_subscription: NPOContextDefaults.user_subscription,
        user_pseudoid: NPOContextDefaults.user_pseudoid,
        user_id: NPOContextDefaults.user_id,
        user_profile_type: NPOContextDefaults.user_profile_type,
      };
    },
    getPlugins: () => plugins || [],
    getParty: () => partyId,
    getIsNewParty,
    getSession,
    getSerializedSessionInfo: getSerializedSessionInfo,
  };
};

export function newTagFromSession(
  context: FromSessionInitialisationProps,
  plugins?: TagPlugin[],
  logLevel?: LogLevel
): NPOTag | undefined {
  const existingSessionInfo: SessionInfo | undefined =
    context.serializedSessionInfo
      ? JSON.parse(context.serializedSessionInfo)
      : undefined;

  if (
    existingSessionInfo?.brand === undefined ||
    existingSessionInfo.brandId === undefined
  ) {
    warn(
      TAG,
      "Unable to build tag from session, the 'brand' of 'brand_id' field is missing from export."
    );
    return;
  }

  const newContext: InternalInitializationProps = {
    brand: existingSessionInfo.brand,
    brand_id: existingSessionInfo.brandId,
    platform: existingSessionInfo.platform,
    platform_version: existingSessionInfo.platformVersion,
    debug: context.debug,
    environment: context.environment,
    disableNmoDam: context.disableNmoDam,
    serializedSessionInfo: context.serializedSessionInfo,
  };

  return _newTag(newContext, plugins, logLevel);
}

/**
 * Function used internally by the sdk to dispatch events to the configured {@link TagPlugin} objects
 *
 * @param eventType {@link EventType}
 * @param npoTag {@link NPOTag} object
 * @param pageContext {@link PageContext} in which to send the event
 * @param additionalContext An object containing additional context properties to be applied to the event
 */
export function submitEvent(
  eventType: EventType,
  npoTag: NPOTag,
  pageContext: PageContext,
  additionalContext?: Partial<StreamContext> &
    Partial<RecommendationContext> &
    Partial<EventArguments>
) {
  const domUtils = DOMUtils();
  const sessionId = npoTag.getSession();
  const storage = getStorage();
  const event: Event = {
    ...npoTag.getContext(),
    ...pageContext,
    ...additionalContext,
    party_id: npoTag.getParty(),
    isNewParty: npoTag.getIsNewParty(),
    is_homepage: pageContext.page === PAGE_HOME,
    client_timestamp: new Date().toISOString(),
    sdk_version: process.env.SDK_VERSION!,
    session_id: sessionId,
    isFirstInSession: storage.get(sessionId) !== undefined,
    location: domUtils.getLocation(),
    referrer: domUtils.getReferrer(),
  };

  // Store partyId and sessionId in storage to update expiry based on recent activity
  storage.set(PARTY_ID_KEY, event.party_id, {
    expires: PARTY_ID_TTL_DAYS,
  });
  storage.set(SESSION_ID_KEY, event.session_id, {
    expires: SESSION_ID_TTL_DAYS,
  });

  // Remove storage that indicates event is first in session
  storage.remove(sessionId);

  debug(TAG, 'Submitting event', eventType, event);

  // Dispatch events to the configured plugins
  npoTag.getPlugins().forEach(value => value.submitEvent(eventType, event));
}
