import {PageContext} from './pageTracker';
import {NPOContext} from './npoTag';
import {StreamContext} from './streamTracker';
import {RecommendationContext} from './recommendationTracker';

/**
 * Type used to track the position of a scroll event
 */
export enum ScrollPercentage {
  SCROLL_25 = 25,
  SCROLL_50 = 50,
  SCROLL_75 = 75,
  SCROLL_100 = 100,
}

/**
 * Arguments passed to stream events
 */
interface StreamEventArguments {
  stream_position: number;
  live_offset?: number;
  stream_seek_from?: number;
  live_offset_from?: number;
  isLiveStream: boolean;
}

/**
 * Arguments passed to recommendation events
 */
interface RecommendationEventArguments {
  recommender: string;
  target_id: string;
  offer_index: number;
}

/**
 * Arguments passed to click events
 */
interface ClickEventArguments {
  click_name: string;
  click_type: string;
  click_chapter_1?: string;
  click_chapter_2?: string;
  click_chapter_3?: string;
}

/**
 * Arguments passed to error events
 */
interface ErrorEventArguments {
  error_message: string;
}

/**
 * Arguments passed to scroll events
 */
interface ScrollEventArguments {
  scroll_percentage: number;
}

export type EventArguments = StreamEventArguments &
  RecommendationEventArguments &
  ClickEventArguments &
  ErrorEventArguments &
  ScrollEventArguments;

/**
 * Object that contains all fields for an analytics event
 */
export interface Event
  extends NPOContext,
    PageContext,
    Partial<StreamContext>,
    Partial<RecommendationContext>,
    Partial<EventArguments> {
  // Fields for tag management
  party_id: string;
  session_id: string;

  // Fields inferred by NPOTag
  client_timestamp: string;
  sdk_version: string;
  location: string;
  referrer?: string;

  // Fields for govolte
  isNewParty: boolean;
  isFirstInSession: boolean;

  // Fields for ATInternet
  is_homepage: boolean;

  // TODO: Remove these fields once click events are implemented
  stream_ad_length?: number;
  query?: string;
  click_position_x?: number;
  click_position_y?: number;
}
