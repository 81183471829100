export enum EventType {
  PAGE_VIEW = 'pageView',
  SEARCH_QUERY = 'searchQuery',
  CHOICE = 'choice',
  OFFER = 'offer',
  CLICK = 'click',
  PAGE_HEARTBEAT = 'pageHeartbeat',
  APP_CRASH = 'appCrash',
  ERROR_DISPLAYED = 'errorDisplayed',
  STREAM_WAYPOINT = 'streamWaypoint',
  STREAM_SEEK = 'streamSeek',
  STREAM_PAUSE = 'streamPause',
  STREAM_RESUME = 'streamResume',
  STREAM_COMPLETE = 'streamComplete',
  STREAM_BUFFERING = 'streamBuffering',
  STREAM_BUFFERING_COMPLETE = 'streamBufferingComplete',
  STREAM_LOAD = 'streamLoad',
  STREAM_LOAD_COMPLETE = 'streamLoadComplete',
  STREAM_START = 'streamStart',
  STREAM_FULLSCREEN = 'streamFullscreen',
  STREAM_WINDOWED = 'streamWindowed',
  STREAM_STOP = 'streamStop',
  MISSING_CLICK = 'missingClick',
  SCROLL = 'scroll',
}
