import {NMO_ID_KEY, NMO_ID_TTL_DAYS} from './storage/keys';
import {EventType} from './eventType';
import {generateGovolteId, generateNmoId} from './idGenerator';
import {NPOTag, submitEvent} from './npoTag';
import {debug} from './utils/log';
import {ABSetup, ABAssign} from './utils/abassign';
import {getStorage} from './storage';
import {ScrollPercentage} from './event';

const TAG = 'PageTracker';

/**
 * Keyword used to determine if a Page is the homepage for NMO DAM
 */
export const PAGE_HOME = 'home';

/**
 * Endpoint used for sending NMO DAM id to Kantar
 */
export const KANTAR_ENDPOINT = 'https://nmonpoendpoint.2cnt.net?vendor=at';

/**
 * Interval at which to send Page Heartbeat events
 */
const PAGE_VISIBLE_UPDATE_INTERVAL = 10 * 1000; // 10 seconds

/**
 * Provides tracking context to the {@link PageTracker}.
 *
 * @remarks
 * This context object contains properties shared by all events in a page.
 */
export interface PageContext {
  readonly pageViewId: string;
  readonly nmoid?: string;
  readonly chapter_1?: string;
  readonly chapter_2?: string;
  readonly chapter_3?: string;
  readonly page?: string;
  readonly content_context_id?: string;
  readonly query_context?: string;
  readonly custom_label1?: string;
  readonly custom_label2?: string;
  readonly custom_label3?: string;
  readonly custom_label4?: string;
  readonly custom_label5?: string;
  readonly error_code?: string;
  readonly broadcasters?: string;
  readonly program?: string;
  readonly heartbeat_enabled?: boolean;
  experiment?: string;
  condition?: string;
  scroll_tracking?: boolean;
}

export interface ClickEventProps {
  /**
   * Name of the click event (e.g. 'kijk later')
   */
  click_name: string;
  /**
   * Type of the click event (e.g. 'navigation', 'action', 'exit', 'download', etc.)
   * @remarks
   * ATInternetPlugin only supports the 4 click types listed below. Events with any other
   * click type will only be sent to Govolte.
   */
  click_type: 'navigation' | 'action' | 'exit' | 'download' | (string & {});
  /**
   * Relative position of the click in the page (optional)
   */
  click_chapter_1?: string;
  /**
   * Relative position of the click in the page (optional)
   */
  click_chapter_2?: string;
  /**
   * Relative position of the click in the page (optional)
   */
  click_chapter_3?: string;
}

export interface ErrorEventProps {
  /**
   * Error message to display
   */
  error_message: string;
}

export interface ScrollEventProps {
  /**
   * Scroll position in the page
   */
  scroll_percentage: number;
}

/**
 * PageTracker interface used to send events and page views to analytics plugins
 */
export interface PageTracker {
  readonly npoTag: NPOTag;
  readonly pageContext: PageContext;

  /**
   * Assign the device/user to a random experimental condition.
   * Assignment is added to the current {@link PageContext}
   *
   * @param setup
   *
   * @example usage:
   * npoTag.login(user_profile='active_profile_123')
   * pageTracker = newPageTracker(npoTag)
   * condition = pageTracker.abAssign({
   *  experiment_name : 'name',
   *  conditions : [
   *    {name : 'redButton', weight : 0.5 },
   *    {name : 'greenButton', weight : 0.5 },
   *  ],
   * });
   * switch (condition){
   *  case 'redButton' : {
   *      // show red button
   *   };
   *  case 'greenButton' : {
   *    // show green button
   *  }
   *  default: {
   *    // always have a backup plan
   *  }
   * };
   * pageTracker.pageView();
   */
  abAssign(setup: ABSetup): string;
  /**
   * Log a page view event for the current {@link PageContext}
   */
  pageView(): void;
  /**
   * Log a click event for the current {@link PageContext}
   */
  click(props: Readonly<ClickEventProps>): void;
  /**
   * Log an error event for the current {@link PageContext}
   */
  errorDisplayed(props: ErrorEventProps): void;
  /**
   * Send a page heartbeat event for the current {@link PageContext}
   */
  sendPageHeartbeat(): void;
  /**
   * Log a scroll event for the current {@link PageContext}
   * It only accepts scroll perentages of 25, 50, 75 and 100
   */
  scroll(props: ScrollEventProps): void;
}

/**
 * Creates a new {@link PageTracker} object with the given context
 *
 * @param npoTag The shared {@link NPOTag} object
 * @param pageContext A {@link PageContext} object
 * @returns A {@link PageTracker} object to log page events
 *
 * @example Minimal usage:
 * ```
 * newPageTracker(npoTag)
 * ```
 * @example Complete usage:
 * ```
 * newPageTracker(npoTag, {
 *   chapter_1: 'programmas',
 *   chapter_2: 'wieisdemol',
 *   chapter_3: 'definale',
 *   page: 'WIDM_123',
 *   content_context_id: 'WIDM_123',
 *   query_context: 'iets over koken',
 *   condition: 'hmpg-A',
 *   custom_label1: 'informatieve content',
 *   custom_label2: 'informatieve content',
 *   custom_label3: 'informatieve content',
 *   custom_label4: 'informatieve content',
 *   custom_label5: 'informatieve content',
 *   error_code: 'error 23: problem authenticating',
 *   broadcasters: 'nos_||_ntr',
 *   program: wieisdemol`,
 *   heartbeat_enabled: true,
 * })
 * ```
 */
export function newPageTracker(
  npoTag: NPOTag,
  pageContext?: Omit<PageContext, 'pageViewId' | 'nmoid'>
): PageTracker {
  // Add pageViewId to pageContext
  let ctx: PageContext = {
    ...pageContext,
    pageViewId: generateGovolteId(),
  };
  // Only generate nmoId and send to Kantar if NMO DAM is not disabled
  if (npoTag.getContext().disableNmoDam !== true) {
    // Get stored nmoId or generate a new one
    const nmoId = getNmoId();
    // Send nmoId to Kantar if required
    sendKantarRequest(nmoId);
    // Add nmoId to pageContext
    ctx = {...ctx, nmoid: nmoId};
  }
  // Heartbeats
  let pageVisibleUpdatesJob: number | null = null;
  const sendPageHeartbeat = () => {
    submitEvent(EventType.PAGE_HEARTBEAT, npoTag, ctx);
  };
  // Start sending heartbeat events
  const startPageVisibleUpdates = () => {
    if (pageVisibleUpdatesJob) return; // Already running
    pageVisibleUpdatesJob = window.setInterval(() => {
      sendPageHeartbeat(); // Send heartbeat event every interval
    }, PAGE_VISIBLE_UPDATE_INTERVAL);
  };
  // Stop sending heartbeat events
  const stopPageVisibleUpdates = () => {
    if (pageVisibleUpdatesJob !== null) {
      window.clearInterval(pageVisibleUpdatesJob);
      pageVisibleUpdatesJob = null;
    }
  };
  const handleVisibilityChange = () => {
    if (document.hidden) {
      stopPageVisibleUpdates();
    } else {
      startPageVisibleUpdates();
    }
  };
  // Attach visibility change event listener only if heartbeat is enabled
  if (ctx.heartbeat_enabled) {
    document.addEventListener('visibilitychange', () => {
      handleVisibilityChange();
    });
    // Make sure to start sending heartbeats when the page is visible at first, not only when visibility changes
    handleVisibilityChange();
  }

  // Scroll tracking
  let lastDispatchedScrollPosition: number = 0;

  return {
    npoTag,
    pageContext: ctx,
    abAssign: function (setup: ABSetup) {
      const identifier =
        npoTag.getContext().user_profile ||
        npoTag.getContext().user_pseudoid ||
        npoTag.getParty();
      const group = ABAssign(identifier, setup);
      // set both the experiment and condition field on the pageContext automatically
      this.pageContext.experiment = setup.experiment_name;
      this.pageContext.condition = group;
      return group;
    },
    pageView: () => submitEvent(EventType.PAGE_VIEW, npoTag, ctx),
    click: (props: Readonly<ClickEventProps>) =>
      submitEvent(EventType.CLICK, npoTag, {...ctx, ...props}),
    errorDisplayed(props: ErrorEventProps) {
      submitEvent(EventType.ERROR_DISPLAYED, npoTag, {...ctx}, {...props});
    },
    sendPageHeartbeat: sendPageHeartbeat,
    scroll(props: ScrollEventProps) {
      const {scroll_percentage} = props;
      // Don't track the scroll if scroll tracking is disabled
      if (!pageContext?.scroll_tracking) {
        console.warn(
          'You must enable scroll tracking in the pageContext to track scroll events'
        );
        return;
      }
      // Dispatch scroll events for all thresholds from ScrollPercentage enum
      const thresholds = Object.values(ScrollPercentage) as number[];
      for (const threshold of thresholds) {
        if (
          // Only dispatch if the scroll position has crossed a new threshold
          scroll_percentage >= threshold &&
          // Only dispatch if given threshold has not been reached before for the current page
          lastDispatchedScrollPosition < threshold
        ) {
          lastDispatchedScrollPosition = threshold;
          submitEvent(
            EventType.SCROLL,
            npoTag,
            {...ctx},
            {...props, scroll_percentage: lastDispatchedScrollPosition}
          );
        }
      }
    },
  };
}

/**
 * Get NMO id from storage or generate a new one
 * @returns a valid NMO DAM id
 *
 * @remarks
 * If a new id is generated it will be stored persistently
 */
export function getNmoId(): string {
  const currentNmoId = getStorage().get(NMO_ID_KEY);
  if (currentNmoId) {
    debug(TAG, `Using nmoId: ${currentNmoId}`);
    return currentNmoId;
  }
  const nmoId = generateNmoId();
  debug(TAG, `Generated new nmoId: ${nmoId}`);
  setNmoId(nmoId);
  return nmoId;
}

/**
 * Override the NMO id. Normally, this is automatically generated by the SDK.
 */
export function setNmoId(nmoId: string): void {
  getStorage().set(NMO_ID_KEY, nmoId, {
    expires: NMO_ID_TTL_DAYS,
  });
}

let isSessionStorageAvailable: boolean | undefined;
let didSendKantarId: boolean | undefined; // only used when session storage is unavailable

/**
 * Send the given NMO id to Kantar so it has a record of it
 * @param nmoId id generated using {@link generateNmoId}
 *
 * @remarks
 * This serves to transfer the ID to a router (called FocalMeter) that panel members
 * have at home. This router will do an intercept of the HTTP(s) traffic, the endpoint
 * itself does not do anything.
 * The ID is sent once per 'session' (here defined as a browser session) to an endpoint
 * provided by Kantar. To prevent the same id being sent more than once per session, we
 * store a key-value pair in {@link sessionStorage}, keyed by the id itself.
 */
export function sendKantarRequest(nmoId: string): void {
  if (isSessionStorageAvailable === undefined) {
    // first time using it - test it (may be forbidden by browser policy)
    try {
      sessionStorage.getItem(nmoId);
      isSessionStorageAvailable = true;
    } catch (e) {
      // probably blocked
      isSessionStorageAvailable = false;
    }
  }

  // Don't send this id if it has already been sent this session
  // Use session storage is available - use local variable otherwise
  if (isSessionStorageAvailable && sessionStorage.getItem(nmoId) === 'sent') {
    return;
  } else if (!isSessionStorageAvailable && didSendKantarId) {
    return;
  }

  debug(TAG, 'Sending nmoId to Kantar', nmoId);
  // Construct the kantar endpoint
  const endpoint = KANTAR_ENDPOINT + '&cs_fpid=' + nmoId;
  // If sendBeacon is unavailable or sendBeacon fails to queue the event, fall back to loading as an image
  if (
    typeof navigator.sendBeacon === 'undefined' ||
    navigator.sendBeacon(endpoint) === false
  ) {
    const img = new Image();
    img.src = endpoint;
  }
  // Store a value in Session Storage to indicate this has been sent to Kantar
  if (isSessionStorageAvailable) {
    sessionStorage.setItem(nmoId, 'sent');
  }
  didSendKantarId = true;
}
